<template>
  <div class="roles nj-grid">
    <nj-question-dialog
      ref="removeQuestionDialog"
      title="Excluir Perfil de Acesso"
      text="Deseja realmente excluir o perfil de acesso?"
    />
    <PermissionsDialog ref="permissionsDialog" />
    <div class="header">
      <span class="title">Listagem de Perfis de Acesso</span>
      <v-form @submit.prevent="search()">
        <v-text-field
          append-icon="mdi-magnify"
          placeholder="Pesquisar"
          outlined
          autofocus
          hide-details
          dense
          class="ma-0 pa-0"
          v-model="searchTerm"
        >
        </v-text-field>
      </v-form>
      <v-btn small color="primary" elevation="0" to="/profiles/new">
        <v-icon left> mdi-plus </v-icon>
        Novo Perfil
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="profiles"
      class="nj-table"
      :loading="loading"
      :options.sync="options"
      loading-text="Buscando..."
      ref="roleDataTable"
      hide-default-footer
      disable-pagination
    >
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | njDateFormat(null, 'DD/MM/YYYY HH:mm') }}
      </template>
      <template v-slot:item.updated_at="{ item }">
        {{ item.updated_at | njDateFormat(null, 'DD/MM/YYYY HH:mm') }}
      </template>
      <template v-slot:item.controls="{ item }">
        <div style="width: 160px">
          <v-btn
            :to="{ name: 'ProfileShow', params: { id: item.id } }"
            title="Visualizar"
            icon
          >
            <v-icon color="green">mdi-eye</v-icon>
          </v-btn>
          <v-btn
            :to="{ name: 'ProfileEdit', params: { id: item.id } }"
            title="Editar"
            icon
          >
            <v-icon color="orange">mdi-square-edit-outline</v-icon>
          </v-btn>
          <v-btn @click="remove(item)" title="Remover" icon>
            <v-icon color="red">mdi-delete</v-icon>
          </v-btn>
          <v-btn @click="openPermissionsDialog(item)" title="Permissões" icon>
            <v-icon color="primary">mdi-account-lock-open-outline</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import Api from '@/resources/NajaSocialApi'
import PermissionsDialog from './permissions_dialog'

export default {
  name: 'Roles',
  components: { PermissionsDialog },
  data: () => ({
    searchTerm: '',
    loading: false,
    profiles: [],
    operations: [],
    options: {},
    headers: [
      { text: 'Nome', value: 'name' },
      { text: 'Criado em', value: 'created_at' },
      { text: 'Última modificação', value: 'updated_at' },
      { text: 'Ações', value: 'controls', sortable: false },
    ],
  }),

  mounted() {
    this.search()
  },

  methods: {
    openPermissionsDialog(profile) {
      this.$refs.permissionsDialog.open(profile)
    },

    remove(item) {
      if (!this.$hasPermission('naja_app_roles_destroy')) {
        alert('Acesso negado')
        return
      }

      this.$refs.removeQuestionDialog.open({
        onConfirm: () => {
          this.$refs.removeQuestionDialog.toggleLoading()
          Api.roles
            .destroy(item.id)
            .then(async () => {
              // this.$toast.show('Perfil de Acesso removido com sucesso', 'success');
              this.search()
            })
            .catch(error => {
              if (error.response) {
                // this.$toast.show('Erro ao remover usuário', 'error');
                // this._setErrors(error.response.data.errors);
              } else {
                throw error
              }
            })
            .finally(() => {
              this.$refs.removeQuestionDialog.close()
            })
        },
      })
    },

    search() {
      this.loading = true

      const query = {
        q: {
          s: ['created_at desc'],
          name_cont: this.searchTerm,
        },
      }

      Api.roles
        .search(query)
        .then(response => {
          this.profiles = response.data.roles
        })
        .catch(error => {
          throw error
        })
        .finally(() => {
          this.loading = false
          this.$scrollTop()
        })
    },
  },
}
</script>
