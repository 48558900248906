<template>
  <v-dialog v-model="dialog" scrollable>
    <template v-slot:default>
      <v-card>
        <v-toolbar color="primary" elevation="0" dark>
          <v-icon class="mr-2"> mdi-account-lock-open-outline </v-icon>
          <v-toolbar-title>
            {{ 'Permissões de Acesso - ' + profile.name }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon dark class="right-aligned" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4 py-4" style="height: 100vh">
          <v-row v-if="loading" class="font-14">
            <v-col>
              <div class="loading-container pt-12">
                <v-progress-circular
                  indeterminate
                  size="64"
                ></v-progress-circular>
              </div>
            </v-col>
          </v-row>
          <div v-else>
            <v-row class="font-14">
              <v-col>
                <v-select
                  label="Tipo"
                  :items="kinds"
                  v-model="kind"
                  required
                  dense
                  outlined
                  hide-details
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="font-14">
              <v-col>
                <v-treeview
                  v-model="selection"
                  ref="treeview"
                  selected-color="primary"
                  :items="items"
                  :filter="filter"
                  :search="kind"
                  :selection-type="'independent'"
                  selectable
                  open-all
                  item-key="itemKey"
                ></v-treeview>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn elevation="0" @click="dialog = false"> Cancelar </v-btn>
          <v-btn
            :loading="submitting"
            elevation="0"
            color="primary"
            @click="submit"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
import Api from '@/resources/NajaSocialApi'
import { clone, unique, union } from 'lodash'

export default {
  name: 'PermissionsDialog',
  data: () => ({
    dialog: false,
    loading: false,
    selection: [],
    items: [],
    title: null,
    kind: '0',
    submitting: false,
    profile: {
      id: null,
      name: null,
    },
    kinds: [{ value: '0', text: 'Acessos Gerais' }],
  }),

  computed: {
    filter() {
      return (item, search) => item.kind.toString() == search
    },
  },

  methods: {
    submit() {
      this.submitting = true
      const selectedNodes = []
      for (const key in this.$refs.treeview.nodes) {
        const node = this.$refs.treeview.nodes[key]
        if (node.isSelected) {
          selectedNodes.push(node)
        }
      }

      const permissions = selectedNodes.map(x => x.item.itemKey)
      const payload = { permissions: permissions }

      Api.roles
        .updateRoleSystemOperations(this.profile.id, payload)
        .then(() => {
          this.$root.$children[0].toast('Permissões salvas com sucesso')
          this.close()
        })
        .catch(error => {
          if (error.response) {
            this.$root.$children[0].toast('Erro ao salvar permissões')
          } else {
            throw error
          }
        })
        .finally(() => {
          this.submitting = false
        })
    },

    _parseItems(operations) {
      const mountChildren = (children, kind) => {
        return children.map(child => {
          const itemKey = `${kind}_${child.id}`
          const newChildren = operations.filter(
            x => x.system_operation_father_id == child.id
          )
          return {
            id: child.id,
            // id: itemKey,
            name: child.name,
            kind: kind,
            itemKey: itemKey,
            children: mountChildren(newChildren, kind),
          }
        })
      }

      // general access
      const generalAccessOperations = operations.filter(
        x => x.system_operation_father_id == null
      )
      generalAccessOperations.forEach(operation => {
        const itemKey = `0_${operation.id}`
        const children = operations.filter(
          x =>
            x.system_operation_father_id == operation.id &&
            !x.requires_organization_branch
        )
        const item = {
          itemKey: itemKey,
          id: operation.id,
          kind: '0',
          name: operation.name,
          children: mountChildren(children, '0'),
        }
        this.items.push(item)
      })

      // branches
      this.kinds
        .filter(x => x.value != 0)
        .forEach(kind => {
          const rootOperations = operations.filter(
            x => x.system_operation_father_id == null
          )

          rootOperations.forEach(operation => {
            const itemKey = `${kind.value}_${operation.id}`
            const children = operations.filter(
              x =>
                x.system_operation_father_id == operation.id &&
                x.requires_organization_branch
            )
            const item = {
              itemKey: itemKey,
              id: operation.id,
              kind: kind.value,
              name: operation.name,
              children: mountChildren(children, kind.value),
            }

            if (item.children.length > 0) {
              this.items.push(item)
            }
          })
        })
    },

    _parseKinds(branches) {
      branches.forEach(branch => {
        this.kinds.push({
          value: branch.id.toString(),
          text: branch.name,
        })
      })
    },

    _parseSelection(items) {
      items.forEach(item => {
        if (item.organization_branch_ids == null) {
          this.selection.push(`0_${item.system_operation_id}`)
        } else {
          item.organization_branch_ids.forEach(branch => {
            this.selection.push(`${branch}_${item.system_operation_id}`)
          })
        }
      })
    },

    clean() {
      this.items = []
      this.selection = []
      this.profile = {
        id: null,
        name: null,
      }
      this.kinds = [{ value: '0', text: 'Acessos Gerais' }]
      this.kind = '0'
    },

    async open(profile) {
      this.clean()

      this.loading = true
      this.dialog = true
      this.profile = { ...this.profile, ...profile }

      try {
        const branchesResponse = await Api.branches()
        this._parseKinds(branchesResponse.data.branches)

        const systemOperationsResponse = await Api.systemOperations.search()
        this._parseItems(systemOperationsResponse.data.system_operations)

        const query = {
          q: {
            role_id_eq: profile.id,
          },
        }
        const roleSystemOperationsResponse =
          await Api.roleSystemOperations.search(query)
        this._parseSelection(
          roleSystemOperationsResponse.data.role_system_operations
        )
        console.log('items', this.items)
        console.log('selection', this.selection)
      } catch (e) {
        this.$root.$children[0].toast('Erro ao buscar dados')
        throw e
      } finally {
        this.loading = false
      }
    },

    close() {
      this.dialog = false
    },
  },
}
</script>
